import type { ApiClient } from '#shopware';

// set the xdebug cookie on the apiClient headers if xdebug was enabled via env
export function useXdebug() {
    const apiClient = inject<ApiClient>('apiClient');
    const runtimeConfig = useRuntimeConfig();
    const xdebugActive = runtimeConfig?.public?.xdebugActive || false;

    if (apiClient && xdebugActive) apiClient.defaultHeaders['Cookie'] = 'XDEBUG_SESSION';
}
