import type { RequestReturnType } from '#shopware';
import type { ProductPrice } from '~/types/models/product/productPrice';
import { Price } from '~/helpers/Price';

const RETRY_TIME = 60000;

/**
 * Composable to manage the price storage
 * @public
 * @category ProductListings
 */
export function useProductPrices(appCall?: boolean) {
    const { apiClient } = useShopwareContext();
    const { isLoggedIn } = useUser();
    const { languageIdChain } = useSessionContext();
    const dateWhenTimeStampInvalid = ref<number | null>(null);
    const isLoading = ref(false);
    const priceStorageKey = 'product-prices';
    const priceDisplayCurrencyStorageKey = 'product-prices-display-currency';
    const priceLanguageStorageKey = 'product-prices-language-id';
    const { sessionContext } = useSessionContext();
    const { currentLanguage } = useCustomInternationalization();

    const { data: productPrices } = useNuxtData<RequestReturnType<'getPricelistOfUser'> | null>('product-prices');

    if (appCall) {
        let intervalId: NodeJS.Timeout | null = null;

        onBeforeMount(() => {
            intervalId = setInterval(() => {
                if (!_timeStampIsValid(productPrices.value)) {
                    dateWhenTimeStampInvalid.value = Date.now();
                }
            }, RETRY_TIME);
        });

        onUnmounted(() => {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
            }
        });
    }

    async function getProductPrices(): Promise<RequestReturnType<'getPricelistOfUser'> | null> {
        try {
            if (!isLoggedIn.value) {
                cleanPriceStorage();
                return null;
            }

            const pricesFromLocalStorage = JSON.parse(localStorage.getItem(priceStorageKey) || 'null');
            const storageValid =
                _timeStampIsValid(pricesFromLocalStorage) && _priceLanguageIsValid() && _priceDisplayCurrencyIsValid();

            if (storageValid) {
                return pricesFromLocalStorage;
            }

            const pricesFromApi = await _getFromApi();

            if (pricesFromApi) {
                return pricesFromApi;
            }
        } catch (e) {
            Logger.captureException(e);
        }

        return null;
    }

    const formatPrice = (
        price: number,
        maxDecimalDigits: number = 2,
        currencySymbol: string | undefined = undefined,
    ): string => {
        return Price.getFormattedPrice(
            price,
            currentLanguage.value?.translationCode?.code,
            currencySymbol ?? sessionContext.value?.currency?.isoCode,
            maxDecimalDigits,
        );
    };
    const formatDisplayPrice = (price: number, maxDecimalDigits: number = 2): string => {
        return Price.getFormattedPrice(
            price,
            currentLanguage.value?.translationCode?.code,
            sessionContext.value?.displayCurrency?.isoCode,
            maxDecimalDigits,
        );
    };

    const cleanPriceStorage = () => {
        localStorage.removeItem(priceStorageKey);
        localStorage.removeItem(priceStorageKey);
        localStorage.removeItem(priceLanguageStorageKey);
        localStorage.removeItem(priceDisplayCurrencyStorageKey);
    };

    const cleanOldShopStore = () => {
        if (!import.meta.client) return;

        const keysToDelete = ['ahPriceTtl', 'ahUserArticlePrices-', 'ahGlobalArticlePrices', 'customerArticles-'];

        for (const storageKey of Object.keys(localStorage)) {
            if (storageKey) {
                for (const keyToDelete of keysToDelete) {
                    if (storageKey?.toLowerCase().includes(keyToDelete.toLowerCase())) {
                        localStorage.removeItem(storageKey);
                    }
                }
            }
        }
    };

    const getPrice = (number: any): ProductPrice => {
        return productPrices?.value?.articles?.[number] || { unbuyable: true };
    };

    const _setToStorage = (priceList: RequestReturnType<'getPricelistOfUser'>) => {
        localStorage.setItem(priceStorageKey, JSON.stringify(priceList));
    };

    const _cleanPriceListResponse = (
        response: RequestReturnType<'getPricelistOfUser'>,
    ): RequestReturnType<'getPricelistOfUser'> => {
        const articles: Record<string, ProductPrice> = {};
        for (const [productNumber, productPrice] of Object.entries(response.articles || {})) {
            if (productPrice.unbuyable || productPrice.price) {
                delete productPrice.apiAlias;
                articles[productNumber] = productPrice;
            }
        }

        return { ...response, articles };
    };

    const _getFromApi = async (): Promise<RequestReturnType<'getPricelistOfUser'> | null> => {
        isLoading.value = true;

        const priceListResponse = await apiClient.invoke('getPricelistOfUser get /pricelist');
        if (priceListResponse.success) {
            const cleanedPriceList = _cleanPriceListResponse(priceListResponse);
            _setToStorage(cleanedPriceList);
            _savePriceLanguageToStorage();
            _savePriceDisplayCurrencyToStorage();
            isLoading.value = false;

            return cleanedPriceList;
        }

        isLoading.value = false;

        return null;
    };

    const _timeStampIsValid = (priceList: RequestReturnType<'getPricelistOfUser'> | null) => {
        if (priceList === null) {
            return false;
        } else {
            return new Date(priceList.expiredDateTime ?? Date.now()) > new Date(Date.now());
        }
    };

    const _savePriceLanguageToStorage = () => {
        localStorage.setItem(priceLanguageStorageKey, languageIdChain.value);
    };

    const _getPriceLanguageFromStorage = () => {
        return localStorage.getItem(priceLanguageStorageKey);
    };

    const _priceLanguageIsValid = () => {
        return languageIdChain.value === _getPriceLanguageFromStorage();
    };

    const _savePriceDisplayCurrencyToStorage = () => {
        localStorage.setItem(priceDisplayCurrencyStorageKey, sessionContext?.value?.displayCurrency?.isoCode);
    };

    const _getPriceDisplayCurrencyFromStorage = () => {
        return localStorage.getItem(priceDisplayCurrencyStorageKey);
    };

    const _priceDisplayCurrencyIsValid = () => {
        return sessionContext?.value?.displayCurrency?.isoCode === _getPriceDisplayCurrencyFromStorage();
    };

    return {
        getProductPrices,
        cleanPriceStorage,
        formatPrice,
        formatDisplayPrice,
        dateWhenTimeStampInvalid,
        productPrices,
        isLoading,
        getPrice,
        cleanOldShopStore,
    };
}
