import { useLocalePath, useI18n, useRoute } from '#imports';
import type { RequestReturnType, Schemas } from '#shopware';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';

/** helper function to dynamically get category destination */
export function useSeoPath() {
    const localePath = useLocalePath();
    const { path } = useRoute();
    const { locale } = useI18n();
    const { apiClient } = useShopwareContext();

    const getPath = (category: Schemas['Category']) => {
        const externalLink = getTranslatedProperty(category, 'externalLink');

        if (externalLink) {
            return externalLink;
        } else if (category?.seoUrls && category?.seoUrls[0]?.seoPathInfo) {
            return localePath('/' + category?.seoUrls[0]?.seoPathInfo);
        } else if (category.translated?.internalLink || category.internalLink) {
            return localePath('/navigation/' + (category.translated?.internalLink || category.internalLink));
        } else {
            return localePath('/navigation/' + category.id);
        }
    };

    const isHomePath = computed<boolean>(() => path === `/${locale.value}`);

    const getSeoPathsForForeignKey = async (foreignKey: string): Promise<RequestReturnType<'getAllSeoUrls'> | null> => {
        try {
            const seoResult = await apiClient.invoke('getAllSeoUrls get /all-seo-urls/{foreignKey}', {
                foreignKey,
            });

            return seoResult;
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    return {
        getPath,
        isHomePath,
        getSeoPathsForForeignKey,
    };
}
