const state = ref(0);

/**
 * Counts how many requests are currently running. When all requests are finished, it calls the `app:allRequestsFinished` Nuxt hook.
 */
export const useRequestWatcher = () => {
    const nuxtApp = useNuxtApp();

    const requestStarted = () => state.value++;
    const requestFinished = () => state.value--;
    const allRequestsFinished = computed(() => state.value === 0);

    const runHook = useDebounceFn(async () => {
        await nuxtApp.callHook('app:allRequestsFinished');
    }, 1000);

    if (import.meta.client) {
        callOnce(() => {
            watch(allRequestsFinished, (finished) => {
                if (finished) {
                    runHook();
                }
            });
        });
    }

    return {
        activeCount: computed(() => state.value),
        requestStarted,
        requestFinished,
        allRequestsFinished,
    };
};
