import { useLazyAsyncData as originalUseLazyAsyncData } from '#app';
import type { AsyncDataOptions } from '#app/composables/asyncData';
import type { NuxtApp } from '#app/nuxt';

/**
 * Wrapper around `useLazyAsyncData` to centrally track how many requests are in progress.
 */
export const useLazyAsyncData = <T>(
    key: string,
    fetcher: (ctx?: NuxtApp) => Promise<T>,
    options?: AsyncDataOptions<T>,
) => {
    const { requestStarted, requestFinished } = useRequestWatcher();

    const result = originalUseLazyAsyncData(
        key,
        async (...args) => {
            try {
                requestStarted();
                return await fetcher(...args);
            } finally {
                requestFinished();
            }
        },
        options,
    );

    return {
        ...result,
    };
};
