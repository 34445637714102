type Price2SpyCountry = {
    countryCode: string;
    countryName: string;
};

type Price2SpyDealer = {
    id: string;
    productNumber: string;
    countryCode: string;
    dealerName: string;
    url: string;
};

const selectedCountry = ref<string | null>(null);

export function usePrice2Spy() {
    const { apiClient } = useShopwareContext();

    const { data: dealers } = useNuxtData<Price2SpyDealer[] | null>('price2spy-dealers');
    const { data: countries } = useNuxtData<Price2SpyCountry[] | null>('price2spy-countries');
    const nothingFound = computed(() => !dealers.value?.length && !countries.value?.length);

    const _fetchAllDealers = async (productNumber: string): Promise<Price2SpyDealer[] | null> => {
        const response = await apiClient.invoke('getPrice2SpyDealers post /price2spy/{productNumber}', {
            productNumber,
        });

        return response && Array.isArray(response) ? response : null;
    };

    const _fetchDealersByCountry = async (
        productNumber: string,
        countryCode: string,
    ): Promise<Price2SpyDealer[] | null> => {
        const response = await apiClient.invoke(
            'getPrice2SpyDealersByCountry post /price2spy/onswitch/{productNumber}/{countryCode}',
            {
                productNumber,
                countryCode,
            },
        );

        return response && Array.isArray(response) ? response : null;
    };

    const getDealers = async (productNumber: string): Promise<Price2SpyDealer[] | null> => {
        try {
            if (!productNumber) return null;

            if (selectedCountry.value) {
                return _fetchDealersByCountry(productNumber, selectedCountry.value);
            } else {
                return _fetchAllDealers(productNumber);
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const getDealersByCountry = async (countryCode: string): Promise<void> => {
        selectedCountry.value = countryCode;
        await refreshNuxtData('price2spy-dealers');
    };

    const getCountries = async (productNumber: string): Promise<Price2SpyCountry[] | null> => {
        try {
            if (!productNumber) return null;

            const response = await apiClient.invoke('getPrice2SpyCountries post /price2spy/countries/{productNumber}', {
                productNumber,
            });

            if (response?.items && Array.isArray(response.items)) {
                return response.items;
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    return {
        dealers,
        countries,
        nothingFound,
        getDealers,
        getCountries,
        getDealersByCountry,
    };
}
