export default defineNuxtRouteMiddleware(async (to) => {
    // prevent redirect loop
    if (to.path.includes('/customer/login')) {
        return;
    }

    const { isLoggedIn, refreshUser } = useUser();
    const localePath = useLocalePath();

    // guard nested pages for specific routes
    const protectedPaths = ['/account', '/checkout', '/fast-order', '/wishlists'];

    if (isLoggedIn.value) return;

    if (protectedPaths.some((path) => to.path.includes(path))) {
        await refreshUser();

        // if still not logged in after refresh, redirect to the login page
        if (!isLoggedIn.value) {
            return navigateTo(localePath('/customer/login'));
        }
    }
});
