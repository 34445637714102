const state = reactive({
    headerVisible: true,
    headerHeight: 0,
});

export function useHeaderState() {
    const { headerVisible, headerHeight } = toRefs(state);

    const setHeaderHeight = (height: number | undefined) => {
        height = height ?? 0;
        if (height === headerHeight.value) return;
        headerHeight.value = height;
    };

    const changeHeaderVisibility = (visible: boolean) => {
        state.headerVisible = visible;
    };

    return {
        headerVisible,
        changeHeaderVisibility,
        headerHeight,
        setHeaderHeight,
    };
}
