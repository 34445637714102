import { joinURL } from 'ufo';
import { reloadNuxtApp } from '#app/composables/chunk';
import { defineNuxtPlugin } from '#app/nuxt';
import { addRouteMiddleware } from '#app/composables/router';

/**
 * overrides the original nuxt:chunk-reload-immediate plugin to handle urls with baseUrl
 */
export default defineNuxtPlugin({
    name: 'nuxt:chunk-reload-immediate',
    setup(nuxtApp) {
        // Remember `to.path` when navigating to a new path: A `chunkError` may occur during navigation, we then want to then reload at `to.path`
        let currentlyNavigationTo: null | string = null;
        addRouteMiddleware((to) => {
            currentlyNavigationTo = to.path;
        });

        const baseUrl = nuxtApp.$config.app.baseURL;
        const reloadNuxtApp_ = (path: string) => {
            if (baseUrl && !path.startsWith(baseUrl)) {
                path = joinURL(baseUrl, path);
            }

            reloadNuxtApp({ persistState: true, path });
        };

        // Reload when a `chunkError` is thrown
        nuxtApp.hook('app:chunkError', () => reloadNuxtApp_(currentlyNavigationTo ?? nuxtApp._route.path));

        // Reload when the app manifest updates
        nuxtApp.hook('app:manifest:update', () => reloadNuxtApp_(nuxtApp._route.path));
    },
});
