export default defineNuxtRouteMiddleware(() => {
    const nuxtApp = useNuxtApp();
    const { viewerCountry, isHydrating, isServerRendered } = useRedirectState();

    isHydrating.value = nuxtApp.isHydrating;
    isServerRendered.value = nuxtApp.payload.serverRendered;

    if (import.meta.server) {
        const headerViewerCountry = useRequestHeader('s-country');
        if (headerViewerCountry) {
            viewerCountry.value = headerViewerCountry;
        }
    }
});
