import Cookies from 'js-cookie';

export interface ConsentPurpose {
    id: string;
    name: string;
}

interface WindowWithCmp extends Window {
    __cmp: any;
}

declare const window: WindowWithCmp;

const algoliaVendorId = 'c63685'; // belogns to purpose 'analyse', c2
const algoliaStorageName = 'algoliaConsent';

export const getConsentData = () => {
    if (!window.__cmp) return;

    return window?.__cmp('getCMPData');
};

export const getConsentStatus = () => {
    if (!window.__cmp) return;

    return window?.__cmp('consentStatus');
};

export const addConsentEventListener = (eventName: string, callback: () => void): void => {
    if (!window.__cmp) return;

    window?.__cmp('addEventListener', [eventName, callback, false], null);
};

export const handleAlgoliaConsent = () => {
    const consent = getConsentData();
    const algoliaConsent = consent?.vendorConsents?.[algoliaVendorId];

    if (algoliaConsent) {
        Cookies.set(algoliaStorageName, 'true');
        return true;
    } else {
        Cookies.set(algoliaStorageName, 'false');
        return false;
    }
};

export const hasAlgoliaConsent = () => {
    return Cookies.get(algoliaStorageName);
};
