export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#0097c8"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"default"},{"name":"msapplication-starturl","content":"/shop/"},{"name":"msapplication-window","content":"width=1024;height=768"},{"name":"msapplication-config","content":"/shop/favicons/browserconfig.xml"},{"name":"msapplication-navbutton-color","content":"#0097c8"},{"name":"msapplication-TileColor","content":"#0097c8"},{"name":"msapplication-TileImage","content":"/shop/favicons/mstile-150x150.png"}],"link":[{"rel":"shortcut icon","type":"image/x-icon","href":"/shop/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/shop/favicons/apple-touch-icon.png"},{"rel":"icon","sizes":"16x16","href":"/shop/favicons/favicon-16x16.png"},{"rel":"icon","sizes":"32x32","href":"/shop/favicons/favicon-32x32.png"},{"rel":"manifest","href":"/shop/favicons/site.webmanifest"},{"rel":"mask-icon","color":"#0097c8","href":"/shop/favicons/safari-pinned-tab.svg"},{"rel":"preconnect","href":"https://www.adamhall.com/store-api"},{"rel":"preconnect","href":"https://delivery.consentmanager.net"}],"style":[],"script":[{"src":"/shop/js/consent-manager/consent-manager-40f1b8b5f8de.js","defer":true,"async":true,"integrity":"sha384-94h4wZoLPO0IA+kgFl8qW804tmdCwbN84/h8jUdbD5b3ziU03AU8bGpjsVwJ/m/C"}],"noscript":[]}

export const appBaseURL = "/shop/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","externalRelAttribute":"nofollow noopener noreferrer","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false