/**
 * Composable to help providing information that can´t be called directly inside a composable or are not available on client-side.
 * E.g. Request-Headers (returns undefined on client-side) or information from useNuxtApp composable (only works in <script setup> or middleware).
 */
export const useRedirectState = () => {
    const viewerCountry = useState<string | null>('redirect-viewer-country', () => null);
    const isHydrating = useState<boolean | undefined>('redirect-is-hydrating', () => undefined);
    const isServerRendered = useState<boolean | undefined>('redirect-server-rendered', () => undefined);

    return {
        viewerCountry,
        isHydrating,
        isServerRendered,
    };
};
