export const removeTrailingSlash = (path: string): string => {
    return path.replace(/\/+$/, '');
};

export const removeForwardSlash = (path: string): string => {
    return path.replace(/^\/+/, '');
};

// converts a seoPathInfo (e.g. 'Flightcases/xyz/') to a route (e.g. '/Flightcases/xyz')
export const convertSeoPathInfoToRoute = (seoPathInfo: string): string => {
    return removeTrailingSlash('/' + seoPathInfo);
};

export const baseDomain = () => {
    const { frontendUrl, basePath } = useRuntimeConfig().public;
    return removeTrailingSlash(frontendUrl) + removeTrailingSlash(basePath);
};
