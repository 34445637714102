<script setup lang="ts">
import type { ComponentSize } from '~/types/components/componentSize';
import type { BackgroundType } from '~/types/components/backgroundType';
import { computed } from 'vue';
import type { RouteObject } from '@shopware-pwa/composables-next';

const props = withDefaults(
    defineProps<{
        to?: string | null | RouteObject;
        color?: string;
        size?: ComponentSize;
        rounded?: boolean;
        disabled?: boolean;
        outline?: boolean;
        backgroundType?: BackgroundType;
        active?: boolean;
        link?: boolean;
    }>(),
    {
        to: null,
        color: 'gray-100',
        size: 'base',
        rounded: false,
        disabled: false,
        outline: false,
        backgroundType: 'light',
        active: false,
        link: false,
    },
);

const component = computed(() => {
    if (props.to && !props.disabled) return resolveComponent('NuxtLink');
    return 'button';
});

/* don't remove; enforce unocss to include dynamically used classes:
bg-brand-primary bg-brand-cart bg-gray-100 bg-gray-200 bg-gray-300 bg-gray-500 bg-white bg-transparent
hover:bg-brand-primary-dark hover:bg-brand-cart-dark hover:bg-gray-100 hover:bg-gray-200 hover:bg-gray-300 hover:bg-gray-500
border-brand-primary border-brand-cart border-gray-100 border-gray-200 border-gray-300 border-gray-500 border-white border-transparent
text-brand-primary text-brand-cart text-gray-100 text-gray-200 text-gray-300 text-gray-500 text-white
 */

/* INFO:
class attribute is nested after the following logic:
- DISABLED
    - yes
        - OUTLINE
            - yes
            - no
                - COLOR white
                    - yes
                    - no
                        - BACKGROUNDTYPE
                            - light
                            - dark
    - no
        - OUTLINE
            - yes
                - COLOR
            - no
                - ACTIVE
                    - no
                        - COLOR
                    - yes
                        - COLOR
- ROUNDED
    - yes
        - SIZE
    - no
        - SIZE
- GENERAL
 */
</script>

<template>
    <component
        :is="component"
        :to="to"
        class="inline-flex items-center justify-center cursor-pointer border transition-colors border-solid"
        :disabled="disabled"
        :class="[
            disabled
                ? [
                      'cursor-not-allowed',
                      outline
                          ? ['text-gray-200 border-gray-200']
                          : [
                                color === 'white'
                                    ? ['border-white', 'bg-white', 'text-gray-200']
                                    : [
                                          backgroundType === 'light'
                                              ? [
                                                    'text-gray-200',
                                                    !link ? 'bg-gray-100 border-gray-100' : 'border-transparent',
                                                ]
                                              : [
                                                    'text-white',
                                                    !link ? 'bg-gray-200 border-gray-200' : 'border-transparent',
                                                ],
                                      ],
                                `bg-${color}`,
                            ],
                  ]
                : [
                      outline
                          ? [
                                `text-${color}`,
                                'bg-transparent',
                                color === 'brand-primary' || color === 'brand-cart' ? 'hover:text-white' : '',
                            ]
                          : [
                                active
                                    ? [
                                          link
                                              ? 'text-brand-primary'
                                              : {
                                                    'text-white bg-brand-primary border-brand-primary':
                                                        color === 'gray-100' || color === 'white',
                                                },
                                      ]
                                    : [
                                          `bg-${color}`,
                                          {
                                              'text-gray-500':
                                                  color === 'gray-100' ||
                                                  color === 'brand-cart' ||
                                                  color === 'white' ||
                                                  link,
                                              'text-white':
                                                  color !== 'white' &&
                                                  color !== 'brand-cart' &&
                                                  color !== 'gray-100' &&
                                                  !link,
                                              'hover:bg-brand-primary hover:border-brand-primary hover:text-white':
                                                  color === 'white',
                                              'hover:text-brand-primary': color === 'gray-100' || link,
                                          },
                                      ],
                            ],
                      `border-${color}`,
                      `hover:bg-${color}-dark`,
                  ],
            rounded
                ? {
                      'rounded-full': true,
                      'p-2.5': size === 'sm',
                      'p-2.875': size === 'base',
                      'p-4.5': size === 'lg',
                  }
                : [
                      'rounded-md',
                      size === 'sm'
                          ? ['min-h-[32px]', !link ? 'px-2' : '']
                          : size === 'lg'
                            ? ['min-h-[52px]', !link ? 'px-6.25' : '']
                            : ['min-h-[42px]', !link ? 'px-5.75' : ''],
                  ],
            {
                'text-md': size === 'sm',
                'text-lg': size === 'base',
                shadow: color === 'white',
            },
        ]"
    >
        <slot />
    </component>
</template>

<style lang="scss" scoped>
// override default router-link-active class for buttons
.router-link-active.text-white {
    @apply text-white #{!important};
}
</style>
