// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useUser as swUseUser } from '@shopware-pwa/composables-next';

/** override sw composable with custom ah properties */
export const useUser = () => {
    const useUserData = swUseUser();

    const defaultShippingAddressId = ref('unset->useShippingAddress');
    const userDefaultShippingAddress = ref('unset->useShippingAddress');

    const isAdmin = computed(() => useUserData.user.value?.extensions?.attributes?.accountAdmin ?? false);
    const isMyCustomerAdmin = computed(
        () => useUserData.user.value?.extensions?.attributes?.myCustomerAccount ?? false,
    );

    return {
        ...useUserData,
        userDefaultShippingAddress,
        defaultShippingAddressId,
        isAdmin,
        isMyCustomerAdmin,
    };
};
