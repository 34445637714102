import { ApiClientError, type ApiError } from '@shopware/api-client';
import Cookies from 'js-cookie';

export class ErrorHandler {
    static handleError(error: any) {
        if (error instanceof ApiClientError) {
            error.details.errors?.forEach((error: ApiError) => {
                if (error.code === 'FRAMEWORK__CUSTOM_ENTITY_NOT_FOUND') {
                    this._handleCustomerNotFound();
                }
            });
        }
    }

    /**
     * @private
     */
    static _handleCustomerNotFound() {
        Cookies.remove('sw-context-token');
        window.location.reload();
    }
}
