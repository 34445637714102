import aa from 'search-insights';
import { getConsentStatus, addConsentEventListener, handleAlgoliaConsent } from '~/helpers/Consent';

type AlgoliaConfig = {
    applicationId: string;
    apiKey: string;
};

export default defineNuxtPlugin((nuxtApp) => {
    let consentGiven = false;

    // initial check if consent exists and no consent modal is shown, e.g on page reload after user action
    const consentStatus = getConsentStatus();
    if (consentStatus?.consentExists) {
        consentGiven = handleAlgoliaConsent();
    }

    // update when user actively rejects/accepts initial consent modal
    addConsentEventListener('consent', () => {
        consentGiven = handleAlgoliaConsent();
    });

    if (consentGiven) {
        const config = useRuntimeConfig();
        const algoliaConfig = config.public.algolia as AlgoliaConfig;

        aa('init', {
            appId: algoliaConfig.applicationId,
            apiKey: algoliaConfig.apiKey,
        });

        nuxtApp.vueApp.provide('aa', aa);
    }
});
