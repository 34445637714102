import type { Ref } from 'vue';
import type { SeoUrls } from '~/types/models/page';
import { Redirect } from '~/helpers/Redirect';
import type { CookieRef } from '#app';
import type { Locale } from '@intlify/core-base';

export type UseLanguageSelectReturn = {
    pathToSeoUrl: (path: string | null, locale?: Locale | undefined) => string;
    changeLanguage: (iso: string | undefined, setCookie: boolean | undefined) => void;
    getFromLocales: (isoOrCode: string, field: 'language' | 'code') => string;
    setSeoPaths: (seoUrls?: SeoUrls | undefined) => Promise<void>;
    setCanonicalUrl: (canonicalUrl?: string | undefined) => void;
    seoPaths: Ref<SeoUrls>;
    convertSeoUrlsToCode: (seoUrls: SeoUrls, pushToSlugs: boolean) => SeoUrls;
    i18nLocaleSlugs: Ref<Record<string, unknown>>;
    autoLanguageRedirect: () => Promise<void>;
    languageCookie: CookieRef<string | null | undefined>;
    changeLanguageOnContextChange: () => Promise<void>;
};

const seoPaths = ref<SeoUrls>({});
const i18nLocaleSlugs = ref<Record<string, unknown>>({});

/**
 * Composable is used in useAsyncData app call to set the context language depending on the i18n locale.
 * It also provides a list of all the languages and the current language.
 */
export function useLanguageSelect(): UseLanguageSelectReturn {
    const { refreshSessionContext, sessionContext } = useSessionContext();
    const { currentLanguage, setLanguage, getLanguageCodeFromId, getLanguageIdFromCode } =
        useCustomInternationalization();
    // @ts-ignore
    const { locale, locales, fallbackLocale } = useI18n();
    const switchLocalePath = useSwitchLocalePath();
    const localePath = useLocalePath();
    const router = useRouter();
    const route = useRoute();
    const { user, isLoggedIn } = useUser();
    const languageCookie = useCookie('shop-language');
    const runtimeConfig = useRuntimeConfig();
    const { setContextTokenHeader } = useShopwareContext();
    const { isHomePath } = useSeoPath();
    const { basePath } = useRuntimeConfig().public;

    const pathToSeoUrl = (path: string | null, locale?: Locale | undefined): string => {
        if (!path) return '';
        return localePath(convertSeoPathInfoToRoute(path), locale) || '';
    };

    // method is used by the language selector to open the current page for the given language
    const changeLanguage = async (iso: string | undefined, setCookie: boolean = false) => {
        if (!iso) return;

        const previousLanguageId = currentLanguage.value?.id;

        try {
            let newRoute;

            const newLanguageId = getLanguageIdFromCode(iso);
            await setLanguage(newLanguageId);

            const seoPath = _getSeoPathForLocale(iso);

            if (seoPath) {
                newRoute = _getNewCmsPageRouteLocation(seoPath, iso);
            } else {
                newRoute = _getNewRouteLocation(iso);
            }

            if (setCookie) {
                languageCookie.value = iso;
            }

            if (route.query) {
                const queryString = new URLSearchParams(route.query as Record<string, string>).toString();
                newRoute.href += queryString ? `?${queryString}` : '';
            }

            Redirect.to(newRoute.href);
        } catch (e) {
            Logger.captureException(e);

            if (previousLanguageId) {
                await setLanguage(previousLanguageId);
            }
        }
    };

    const setSeoPaths = async (seoUrls?: SeoUrls | undefined): Promise<void> => {
        seoPaths.value = {};
        i18nLocaleSlugs.value = {};

        if (isHomePath.value) return;
        if (!seoUrls) {
            for (const l of locales.value) {
                if (typeof l === 'string') continue;

                const localeCode = l.code;
                const isoCode = l.language;
                const fullPath = switchLocalePath(localeCode);
                if (!fullPath) continue;

                const url = new URL(fullPath, _getI18nBaseUrl());
                // remove query parameters
                url.search = '';
                const localePathRegExp = new RegExp(`^/${localeCode}(?:/|$)`);
                const seoPath = url.pathname.replace(localePathRegExp, '');

                seoPaths.value[localeCode] = seoPath;
                if (isoCode) {
                    seoPaths.value[isoCode] = seoPath;
                }
            }

            return;
        }

        seoPaths.value = convertSeoUrlsToCode(seoUrls, true);
    };

    const convertSeoUrlsToCode = (seoUrls: SeoUrls, pushToSlugs: boolean = false): SeoUrls => {
        const seoUrlsWithCode: SeoUrls = {};

        for (const [languageId, seoPath] of Object.entries(seoUrls)) {
            const isoCode = getLanguageCodeFromId(languageId);

            if (isoCode) {
                const cleanSeoPath = seoPath.replace(/\/+$/g, '');
                const localeCode = getFromLocales(isoCode);
                seoUrlsWithCode[isoCode] = cleanSeoPath;
                seoUrlsWithCode[localeCode] = cleanSeoPath;

                if (pushToSlugs) {
                    i18nLocaleSlugs.value[localeCode] = { slug: cleanSeoPath };
                }
            }
        }

        return seoUrlsWithCode as SeoUrls;
    };

    const _getI18nBaseUrl = (): string => String(runtimeConfig?.public?.i18n?.baseUrl || '');

    const setCanonicalUrl = (canonicalUrl: string | undefined) => {
        const cleanedCanonicalUrl = canonicalUrl?.replace(/^\/+/g, '');
        let canonicalPath = cleanedCanonicalUrl
            ? pathToSeoUrl(cleanedCanonicalUrl)
            : router.currentRoute.value.fullPath;

        if (!canonicalPath.startsWith(basePath)) {
            canonicalPath = (basePath + canonicalPath).replace(/\/\/+/g, '/');
        }

        const canUrl = _getI18nBaseUrl() + canonicalPath;

        useHead({
            link: [{ id: 'i18n-can', rel: 'canonical', href: canUrl }],
            meta: [{ id: 'i18n-og-url', property: 'og:url', content: canUrl }],
        });
    };

    const _getSeoPathForLocale = (locale: string) => {
        return seoPaths.value[locale] ?? seoPaths.value[locale] ?? null;
    };

    // get the new route for a cms page
    const _getNewCmsPageRouteLocation = (seoPathInfo: string, iso: string) => {
        return router.resolve(pathToSeoUrl(seoPathInfo, getFromLocales(iso)));
    };

    // get the new route for all other pages like checkout, account, etc.
    const _getNewRouteLocation = (iso: string) => {
        return router.resolve(switchLocalePath(getFromLocales(iso)));
    };

    // find and get the iso or code (e.g. 'de' or 'de-DE') value for a given language (e.g. 'de' or 'de-DE')
    const getFromLocales = (isoOrCode: string, field: 'language' | 'code' = 'code'): Locale => {
        const foundLocale = locales.value?.find((locale) =>
            typeof locale !== 'string' ? locale.language === isoOrCode || locale.code === isoOrCode : false,
        );

        if (foundLocale && typeof foundLocale !== 'string' && typeof foundLocale[field] !== 'undefined') {
            return foundLocale[field] as Locale;
        }

        return getFromLocales(fallbackLocale.value as string, field);
    };

    const changeLanguageOnContextChange = async () => {
        setContextTokenHeader();
        await refreshSessionContext();
        const currentLocaleIso = getFromLocales(locale.value, 'language');
        const sessionContextLocaleIso = sessionContext.value?.language?.locale?.code;

        if (currentLocaleIso !== sessionContextLocaleIso) {
            await changeLanguage(sessionContextLocaleIso);
        }
    };

    /**
     * Detects the best language for the user based on several points.
     * When the user is not logged in, it only checks and redirects on the home page on initial visit.
     */
    const autoLanguageRedirect = async (): Promise<void> => {
        const { viewerCountry, isHydrating, isServerRendered } = useRedirectState();
        const isInitialClientLoad =
            import.meta.client &&
            !!isHydrating.value &&
            !!isServerRendered.value &&
            !document.referrer.startsWith(location.origin);
        const isRootPath = router.currentRoute.value.path === localePath('/', locale.value);

        // Users that are not logged in are only allowed to redirect on initial page load at root (home) page
        if (!isLoggedIn.value && (!isInitialClientLoad || !isRootPath)) return;

        const currentLocaleIso = getFromLocales(locale.value, 'language');

        // Prio 1: Redirect based on Cookie
        if (languageCookie.value && _localeExists(languageCookie.value)) {
            const langaugeCookieLocale = getFromLocales(languageCookie.value);

            if (!_isCurrentLocale(langaugeCookieLocale)) {
                await changeLanguage(languageCookie.value);
            }

            return;
        }

        // Prio 2: Redirect based on Users language settings
        if (user.value && user.value?.languageId) {
            const userLanguageIso = getLanguageCodeFromId(user.value.languageId);
            const userLanguageLocale = getFromLocales(userLanguageIso);

            if (_localeExists(userLanguageIso)) {
                if (!_isCurrentLocale(userLanguageLocale)) {
                    await changeLanguage(userLanguageIso);
                }

                return;
            }
        }

        // Prio 3: Redirect based on the country the website visitor
        const localeForViewerCountry = _getLocaleForCountry(viewerCountry.value);
        if (localeForViewerCountry && _localeExists(localeForViewerCountry)) {
            const isoCodeForViewerCountryLocale = getFromLocales(localeForViewerCountry, 'language');
            if (isoCodeForViewerCountryLocale && isoCodeForViewerCountryLocale !== currentLocaleIso) {
                await changeLanguage(isoCodeForViewerCountryLocale);
            }

            return;
        }

        // Prio 4: Redirect based Browsers language settings
        const browserLocale = useBrowserLocale();
        if (browserLocale) {
            const browserLocaleIso = getFromLocales(browserLocale, 'language');
            if (browserLocaleIso && browserLocaleIso !== currentLocaleIso) {
                await changeLanguage(browserLocaleIso);
            }

            return;
        }
    };

    const _localeExists = (isoOrLocale: string) => {
        return !!locales.value?.some(
            (locale) => typeof locale !== 'string' && (locale.code === isoOrLocale || locale.language === isoOrLocale),
        );
    };

    const _isCurrentLocale = (localeCode: string): boolean => localeCode === locale.value;

    const _getLocaleForCountry = (country: string | null | undefined): string | null => {
        if (!country) return null;

        const appConfig = useAppConfig();
        const countryLocalesConfig: Record<string, string[]> = appConfig.countryLocales as Record<string, string[]>;
        const countryLocale = Object.keys(countryLocalesConfig).find((locale) =>
            countryLocalesConfig[locale].includes(country.toUpperCase()),
        );

        return countryLocale?.toLowerCase() ?? null;
    };

    return {
        pathToSeoUrl,
        changeLanguage,
        getFromLocales,
        setSeoPaths,
        setCanonicalUrl,
        seoPaths,
        convertSeoUrlsToCode,
        i18nLocaleSlugs,
        autoLanguageRedirect,
        languageCookie,
        changeLanguageOnContextChange,
    };
}
