<script setup lang="ts">
import type { GlobalComponents, Ref } from 'vue';

export type SharedOffcanvas = {
    offcanvasId: Ref<string>;
    offcanvasContent: Ref<string>;
    offcanvasProps: Ref<object | null>;
    visible: Ref<boolean>;
    open: <T extends keyof GlobalComponents>(component: T, id: string, props?: object | null) => void;
    close: () => void;
    transitionEnd: () => void;
};

const { close, transitionEnd, visible, offcanvasId } = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;

const isFilterOffcanvas = computed(() => offcanvasId.value === 'algolia-filters');

// scroll lock if offcanvas is open
useHead({
    bodyAttrs: {
        class: computed(() => (visible.value ? 'overflow-hidden' : '')),
    },
});
</script>

<template>
    <Transition
        name="offcanvas-overlay"
        enterFromClass="opacity-0"
        enterToClass="opacity-100"
        enterActiveClass="ease-in-out duration-300"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
        leaveActiveClass="ease-in-out duration-300"
    >
        <div
            v-show="visible"
            class="fixed z-10 inset-0 overflow-y-auto bg-gray-500 bg-opacity-80"
            data-testid="shared-offcanvas"
            @click="close"
        >
            <div class="fixed inset-y-0 right-0 flex max-w-full">
                <Transition
                    name="offcanvas-inner"
                    enterFromClass="translate-x-full"
                    enterToClass="translate-x-0"
                    enterActiveClass="transform transition ease-in-out duration-400 sm:duration-400"
                    leaveFromClass="translate-x-0"
                    leaveToClass="translate-x-full"
                    leaveActiveClass="transform transition ease-in-out duration-400 sm:duration-400"
                    @after-leave="transitionEnd"
                >
                    <div
                        v-show="visible"
                        class="max-w-md w-screen"
                    >
                        <div
                            class="flex h-full flex-col bg-white shadow"
                            role="dialog"
                            aria-modal="true"
                            @click.stop
                        >
                            <button
                                class="bg-white text-left py-3 px-4.5 flex justify-between items-center gap-3.5 border-b-1 z-1 lg:justify-end lg:flex-row-reverse"
                                :title="$t('mobileMenu.closeBtn')"
                                @click="close"
                            >
                                {{ isFilterOffcanvas ? $t('mobileMenu.filterCloseBtn') : $t('mobileMenu.closeBtn') }}
                                <SvgoXMark class="text-gray-500 h-4.5 w-auto" />
                            </button>

                            <div
                                id="offcanvas-content"
                                class="h-full overflow-y-auto"
                            />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>
