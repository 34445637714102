// redirect to route without trailing slash
// @ts-ignore
export default defineNuxtRouteMiddleware((to) => {
    if (to.path !== '/' && to.path.endsWith('/')) {
        const { path, query, hash } = to;
        const nextPath = path.replace(/\/+$/, '') || '/';
        const nextRoute = { path: nextPath, query, hash };

        return navigateTo(nextRoute, { redirectCode: 301 });
    }
});
