import { default as _91_46_46_46slug_93w5kGEAc39qMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue?macro=true";
import { default as index1T04PsXJEsMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue?macro=true";
import { default as _91id_93QDKsp0z8oFMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue?macro=true";
import { default as indexIN3sQE8fQ7Meta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue?macro=true";
import { default as paymentbloAkNvhPSMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue?macro=true";
import { default as pricelistFMPiAFOEpaMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue?macro=true";
import { default as profilePRBj5XtK3JMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue?macro=true";
import { default as cartMBm7ZDP9IWMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue?macro=true";
import { default as indexnrI618vWr5Meta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue?macro=true";
import { default as indexnZAVVEMaSZMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidzyg3jXtrcGMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaid_46clientDZ5XJRQShpMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as authorizeQj4W87wSKVMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue?macro=true";
import { default as loginG4VIDLyvoWMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue?macro=true";
import { default as logoutfJncy1kWjGMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue?macro=true";
import { default as fast_45ordergLwNYhDq1UMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue?macro=true";
import { default as mycustomerZZOM8ysTzoMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue?macro=true";
import { default as searchPjbZoRN3MIMeta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue?macro=true";
import { default as wishlists4pGD1ThKM0Meta } from "/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue?macro=true";
export default [
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "slug___pl",
    path: "/pl/:slug(.*)*",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/[...slug].vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account___it",
    path: "/it/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account___pl",
    path: "/pl/account",
    meta: index1T04PsXJEsMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/index.vue")
  },
  {
    name: "account-order-detail-id___de",
    path: "/de/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order-detail-id___en",
    path: "/en/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order-detail-id___es",
    path: "/es/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order-detail-id___fr",
    path: "/fr/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order-detail-id___it",
    path: "/it/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order-detail-id___pl",
    path: "/pl/account/order/detail/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/detail/[id].vue")
  },
  {
    name: "account-order___de",
    path: "/de/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-order___en",
    path: "/en/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-order___es",
    path: "/es/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-order___fr",
    path: "/fr/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-order___it",
    path: "/it/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-order___pl",
    path: "/pl/account/order",
    meta: indexIN3sQE8fQ7Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/order/index.vue")
  },
  {
    name: "account-payment___de",
    path: "/de/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-payment___en",
    path: "/en/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-payment___es",
    path: "/es/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-payment___fr",
    path: "/fr/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-payment___it",
    path: "/it/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-payment___pl",
    path: "/pl/account/payment",
    meta: paymentbloAkNvhPSMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/payment.vue")
  },
  {
    name: "account-pricelist___de",
    path: "/de/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-pricelist___en",
    path: "/en/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-pricelist___es",
    path: "/es/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-pricelist___fr",
    path: "/fr/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-pricelist___it",
    path: "/it/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-pricelist___pl",
    path: "/pl/account/pricelist",
    meta: pricelistFMPiAFOEpaMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/pricelist.vue")
  },
  {
    name: "account-profile___de",
    path: "/de/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "account-profile___es",
    path: "/es/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "account-profile___fr",
    path: "/fr/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "account-profile___it",
    path: "/it/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "account-profile___pl",
    path: "/pl/account/profile",
    meta: profilePRBj5XtK3JMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/account/profile.vue")
  },
  {
    name: "checkout-cart___de",
    path: "/de/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___en",
    path: "/en/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___es",
    path: "/es/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___fr",
    path: "/fr/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___it",
    path: "/it/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___pl",
    path: "/pl/checkout/cart",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/cart.vue")
  },
  {
    name: "checkout___de",
    path: "/de/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout___es",
    path: "/es/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout___it",
    path: "/it/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout___pl",
    path: "/pl/checkout",
    meta: indexnrI618vWr5Meta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-id___de",
    path: "/de/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___en",
    path: "/en/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___es",
    path: "/es/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___fr",
    path: "/fr/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___it",
    path: "/it/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___pl",
    path: "/pl/checkout/success/:id()",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id-paid___de",
    path: "/de/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___en",
    path: "/en/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___es",
    path: "/es/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___fr",
    path: "/fr/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___it",
    path: "/it/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___pl",
    path: "/pl/checkout/success/:id()/paid",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-unpaid___de",
    path: "/de/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "checkout-success-id-unpaid___en",
    path: "/en/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "checkout-success-id-unpaid___es",
    path: "/es/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "checkout-success-id-unpaid___fr",
    path: "/fr/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "checkout-success-id-unpaid___it",
    path: "/it/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "checkout-success-id-unpaid___pl",
    path: "/pl/checkout/success/:id()/unpaid",
    component: () => createClientPage(() => import("/buddy/adamhall-sw6/frontends/ahshop/pages/checkout/success/[id]/unpaid.client.vue"))
  },
  {
    name: "customer-authorize___de",
    path: "/de/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-authorize___en",
    path: "/en/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-authorize___es",
    path: "/es/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-authorize___fr",
    path: "/fr/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-authorize___it",
    path: "/it/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-authorize___pl",
    path: "/pl/customer/authorize",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/authorize.vue")
  },
  {
    name: "customer-login___de",
    path: "/de/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-login___en",
    path: "/en/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-login___es",
    path: "/es/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-login___fr",
    path: "/fr/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-login___it",
    path: "/it/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-login___pl",
    path: "/pl/customer/login",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/login.vue")
  },
  {
    name: "customer-logout___de",
    path: "/de/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "customer-logout___en",
    path: "/en/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "customer-logout___es",
    path: "/es/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "customer-logout___fr",
    path: "/fr/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "customer-logout___it",
    path: "/it/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "customer-logout___pl",
    path: "/pl/customer/logout",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/customer/logout.vue")
  },
  {
    name: "fast-order___de",
    path: "/de/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "fast-order___en",
    path: "/en/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "fast-order___es",
    path: "/es/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "fast-order___fr",
    path: "/fr/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "fast-order___it",
    path: "/it/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "fast-order___pl",
    path: "/pl/fast-order",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/fast-order.vue")
  },
  {
    name: "mycustomer___de",
    path: "/de/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "mycustomer___en",
    path: "/en/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "mycustomer___es",
    path: "/es/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "mycustomer___fr",
    path: "/fr/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "mycustomer___it",
    path: "/it/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "mycustomer___pl",
    path: "/pl/mycustomer",
    meta: mycustomerZZOM8ysTzoMeta || {},
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/mycustomer.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "search___pl",
    path: "/pl/search",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/search.vue")
  },
  {
    name: "wishlists___de",
    path: "/de/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  },
  {
    name: "wishlists___en",
    path: "/en/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  },
  {
    name: "wishlists___es",
    path: "/es/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  },
  {
    name: "wishlists___fr",
    path: "/fr/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  },
  {
    name: "wishlists___it",
    path: "/it/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  },
  {
    name: "wishlists___pl",
    path: "/pl/wishlists",
    component: () => import("/buddy/adamhall-sw6/frontends/ahshop/pages/wishlists.vue")
  }
]