export class Price {
    static getFormattedPrice(
        value: number | string | undefined,
        localeCode: string | undefined,
        currencyCode: string | undefined,
        maxDecimalDigits: number = 2,
    ): string {
        if (typeof value === 'undefined') {
            return '';
        }

        if (!localeCode || !currencyCode) {
            return value.toString();
        }

        const numeric = +value;
        const checkNumeric = Math.abs(numeric);

        let digits = maxDecimalDigits ? maxDecimalDigits : 2;

        if (checkNumeric >= 1.0) {
            // prices above 1.0€ should never be displayed with more than 2 decimal digits
            digits = 2;
        } else if (checkNumeric >= 0.5) {
            // prices above 0.5 € and beneath 1.0€ should never be displayed with more than 3 decimal digits
            digits = 3;
        } else if (digits < 4) {
            // prices below 0.5€ should be displayed with at least 4 decimal digits
            digits = 4;
        }

        return new Intl.NumberFormat(localeCode, {
            style: 'currency',
            currency: currencyCode,
            maximumFractionDigits: digits,
        }).format(numeric);
    }

    static round2Decimals = (value: number) => {
        // better Math.round to avoid some rounding issues, still has own incorrectly rounded edgecases
        return Math.round(value * 100 * (1 + Number.EPSILON)) / 100;
    };
}
