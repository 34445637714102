export class UrlHelper {
    static getDomainFromString(domain: string): string {
        return new URL(domain).hostname;
    }

    static getAbsoluteLink(link: string | undefined): string {
        if (!link) return '';

        return link.startsWith('http') ? link : `https://${link}`;
    }
}
