import type {
    ContactInformation,
    ContactInformationItem,
    ContactInformationOpeningTimeDay,
    ContactInformationOpenings,
    ContactInformationPreparedOpening,
} from '~/types/models/contactInformation';
import { ApiClientError } from '@shopware/api-client';

export function useContactInformation() {
    const { apiClient } = useShopwareContext();
    const { data: contactInformation } = useNuxtData<ContactInformation | null>('contact-information');
    const { t } = useI18n();

    const serviceContact = computed<ContactInformationItem | null>(
        () => contactInformation.value?.find((contact) => contact.serviceContactType === 'HOTLINE') || null,
    );

    const _serviceContactOpenings = computed(() => serviceContact.value?.openings as ContactInformationOpenings);

    const getContactInformation = async (): Promise<ContactInformation | null> => {
        try {
            const response = await apiClient.invoke('getAllContacts get /contact-all');
            return response?.data ?? null;
        } catch (error) {
            if (error instanceof ApiClientError && error.status === 409) return null;

            Logger.captureException(error);
        }

        return null;
    };

    const getContactInformationByType = async (type: string): Promise<ContactInformation | null> => {
        try {
            if (!type) return null;
            const response = await apiClient.invoke('getContactsByType get /contact-by-type/{type}', {
                type,
            });
            return response?.data ?? null;
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const getContactInformationById = async (id: string): Promise<ContactInformation | null> => {
        try {
            if (!id) return null;
            const response = await apiClient.invoke('getContactsById get /contact-by-id/{id}', {
                id,
            });
            return response?.data ?? null;
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const _daysMap: { [key: string]: string } = {
        mon: t('service.times.days.mon'),
        tue: t('service.times.days.tue'),
        wed: t('service.times.days.wed'),
        thu: t('service.times.days.thu'),
        fri: t('service.times.days.fri'),
        sat: t('service.times.days.sat'),
        sun: t('service.times.days.sun'),
    };

    // add leading zero to the hour and minute
    const _formatTime = ({ hour, minute }: { hour: number; minute: number }) =>
        `${String(hour)}:${String(minute).padStart(2, '0')}`;

    const _sortDays = (openings: ContactInformationOpenings) => {
        const days = Object.keys(openings) as ContactInformationOpeningTimeDay[];
        const dayOrder = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        return days.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
    };

    const _translateOpenings = (openings: ContactInformationPreparedOpening[]) => {
        return openings.map((opening) => {
            for (const [day, translation] of Object.entries(_daysMap)) {
                const regex = new RegExp(`\\b${day}\\b`, 'g'); // Regex to match whole words
                opening.day = opening.day.replace(regex, translation);
            }

            return opening;
        });
    };

    const _groupConsecutiveDays = (sortedDays: ContactInformationOpeningTimeDay[]) => {
        const result: ContactInformationPreparedOpening[] = [];
        let currentRange: string[] = [];
        let currentTimes = '';

        const pushResult = () => {
            if (currentRange.length && currentTimes) {
                const rangeString =
                    currentRange.length === 1
                        ? currentRange[0]
                        : `${currentRange[0]} - ${currentRange[currentRange.length - 1]}`;
                result.push({ day: rangeString, time: currentTimes });
            }
        };

        // Group consecutive days with the same opening times
        sortedDays.forEach((day: ContactInformationOpeningTimeDay) => {
            const times = _serviceContactOpenings.value?.[day]?.[0] || undefined;
            if (!times) return;

            const timesString = `${_formatTime(times.start)} - ${_formatTime(times.end)}`;

            if (currentTimes === timesString) {
                currentRange.push(day);
            } else {
                pushResult();
                currentRange = [day];
                currentTimes = timesString;
            }
        });

        pushResult();

        return result;
    };

    const groupedOpenings = computed<ContactInformationPreparedOpening[]>(() => {
        if (!_serviceContactOpenings.value) return [] as ContactInformationPreparedOpening[];

        const sortedDays: ContactInformationOpeningTimeDay[] = _sortDays(_serviceContactOpenings.value);
        const groupedDays = _groupConsecutiveDays(sortedDays);
        return _translateOpenings(groupedDays);
    });

    return {
        contactInformation,
        getContactInformation,
        getContactInformationByType,
        getContactInformationById,
        serviceContact,
        groupedOpenings,
    };
}
