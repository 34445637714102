import consola from 'consola';
import {
    isInitialized as isSentryInitialized,
    captureException as captureSentryException,
    captureMessage as captureSentryMessage,
} from '@sentry/vue';
// @ts-ignore
import type { ExclusiveEventHintOrCaptureContext } from '@sentry/core/build/types/utils/prepareEvent';
import { ApiClientError } from '@shopware/api-client';
import { ObjectHelper } from '~/helpers/ObjectHelper';
import { object, array, string, number } from 'yup';
import type { CaptureContext, ScopeContext, SeverityLevel } from '@sentry/core';

export class Logger {
    static _sentryInitialized() {
        return isSentryInitialized() ?? false;
    }

    static async isAlgoliaException(exception: any): Promise<boolean> {
        if (!ObjectHelper.isObject(exception)) return false;
        const schema = object().shape({
            message: string().required(),
            name: string().required(),
            status: number().optional(),
            transporterStackTrace: array().required(),
        });

        return schema.isValid(exception);
    }

    static captureMessage(message: string, captureContext?: CaptureContext | SeverityLevel): void {
        if (this._sentryInitialized()) {
            // @ts-ignore
            captureSentryMessage(message, captureContext);
        } else {
            this._logToConsole(message, captureContext);
        }
    }

    static async captureException(exception: any, hint?: ExclusiveEventHintOrCaptureContext): Promise<void> {
        const isApiClientError = exception instanceof ApiClientError;
        const isError = exception instanceof Error;

        if (!isError && !isApiClientError) {
            const isAlgoliaError = await this.isAlgoliaException(exception);
            if (isAlgoliaError) {
                hint = (hint || {}) as ScopeContext;
                hint.extra = {
                    ...hint.extra,
                    status: exception?.status,
                    transporterStackTrace: JSON.stringify(exception?.transporterStackTrace, undefined, 4),
                };

                const newAlgoliaError = new Error(exception.message);
                newAlgoliaError.name = exception.name;

                return this.captureException(newAlgoliaError, hint);
            }
            return;
        }

        if (isApiClientError) {
            hint = (hint || {}) as ScopeContext;
            hint.extra = {
                ...hint.extra,
                apiErrorDetails: JSON.stringify(exception.details, undefined, 4),
            };
        }

        if (this._sentryInitialized()) {
            captureSentryException(exception, hint);
        } else {
            this._logToConsole(exception, hint, 'error');
        }

        ErrorHandler.handleError(exception);
    }

    static _logToConsole(
        messageOrException: any,
        context?: ExclusiveEventHintOrCaptureContext | CaptureContext | SeverityLevel,
        logLevel?: SeverityLevel,
    ): void {
        switch (logLevel ?? this._contextLogLevel(context)) {
            case 'fatal':
                consola.fatal(messageOrException, context);
                break;
            case 'error':
                consola.error(messageOrException, context);
                break;
            case 'warning':
                consola.warn(messageOrException, context);
                break;
            case 'info':
                consola.warn(messageOrException, context);
                break;
            case 'debug':
                consola.debug(messageOrException, context);
                break;
            default:
                consola.log(messageOrException, context);
                break;
        }
    }

    static _contextLogLevel(
        context?: ExclusiveEventHintOrCaptureContext | CaptureContext | SeverityLevel,
    ): SeverityLevel | undefined {
        if (!context) return;

        if (typeof context === 'string') {
            return context as SeverityLevel;
        }

        if (typeof context === 'object' && Object.hasOwn(context, 'level') && typeof context.level === 'string') {
            return (context as ScopeContext).level;
        }
    }
}
