import type { RouterConfig } from '@nuxt/schema';
import qs from 'qs';
import { isChangingPage } from '#app/components/utils';
// @ts-ignore
import { appPageTransition as defaultPageTransition } from '#build/nuxt.config.mjs';
import type { RouteLocationNormalized } from 'vue-router';

export default <RouterConfig>{
    scrollBehaviorType: 'smooth',
    scrollBehavior(to, from, savedPosition) {
        const nuxtApp = useNuxtApp();
        const router = useRouter();
        // @ts-ignore
        const behavior = router.options?.scrollBehaviorType ?? 'auto';
        let position = savedPosition || void 0;
        const routeAllowsScrollToTop =
            typeof to.meta.scrollToTop === 'function' ? to.meta.scrollToTop(to, from) : to.meta.scrollToTop;
        if (!position && from && to && routeAllowsScrollToTop !== false && isChangingPage(to, from)) {
            position = { left: 0, top: 0 };
        }
        if (to.path === from.path) {
            if (
                (from.hash && !to.hash) ||
                (isSearchPage(from.name) && isSearchPage(to.name) && from.query?.query !== to.query?.query)
            ) {
                return { left: 0, top: 0 };
            }
            if (to.hash) {
                return _getHashBehaviour(to.hash, behavior);
            }
        }

        const hasTransition = (route: RouteLocationNormalized) =>
            !!(route.meta.pageTransition ?? defaultPageTransition);
        const hookToWait = hasTransition(from) && hasTransition(to) ? 'page:transition:finish' : 'page:finish';
        return new Promise((resolve) => {
            nuxtApp.hooks.hookOnce(to.hash ? 'app:allRequestsFinished' : hookToWait, async () => {
                await new Promise((res) => requestAnimationFrame(res));

                if (to.hash) {
                    // @ts-ignore
                    position = _getHashBehaviour(to.hash, behavior);
                }
                resolve(position);
            });
        });
    },
    parseQuery: qs.parse,
    stringifyQuery: qs.stringify,
};

function isSearchPage(routeName?: any) {
    return typeof routeName === 'string' && routeName.startsWith('search__');
}

function _getHashBehaviour(hash: string, behavior: 'smooth' | 'auto' | undefined) {
    if (hash.startsWith('#product-')) {
        return {
            el: hash,
            top: scrollOffset,
            behavior,
        };
    }

    return {
        el: hash,
        top: stickyHeaderHeight,
        behavior,
    };
}
