import type { NuxtError } from '#app';
import { useI18n } from 'vue-i18n';

interface ErrorDetails {
    errorHeading: string | number;
    errorMessage: string;
}

const blockedByBrowserErrors: Array<string | RegExp> = [/^Unreachable hosts/];
export const isBlockedByBrowserError = (message?: string) => {
    if (!message) return false;

    return blockedByBrowserErrors.some((pattern) => {
        if (pattern instanceof RegExp) {
            return pattern.test(message);
        }

        return message.includes(pattern);
    });
};

export const getErrorPageText = (error: Partial<NuxtError>): ErrorDetails => {
    const { t } = useI18n();

    const defaultMessage: ErrorDetails = {
        errorHeading: error.statusCode!,
        errorMessage: error.statusMessage!,
    };

    const fallbackMessage: ErrorDetails = {
        errorHeading: t('notifications.danger'),
        errorMessage: t('errors.message-default'),
    };

    const errorMessageMap: Record<number, ErrorDetails> = {
        404: {
            errorHeading: t('errors.notFoundHeader'),
            errorMessage: t('errors.notFoundMsg'),
        },
        401: {
            errorHeading: t('errors.notAuthorizedHeader'),
            errorMessage: t('errors.notAuthorizedMsg'),
        },
        408: {
            errorHeading: t('errors.requestTimeoutHeader'),
            errorMessage: t('errors.requestTimeoutMsg'),
        },
        500: {
            errorHeading: t('errors.internalServerErrorHeader'),
            errorMessage: t('errors.internalServerErrorMsg'),
        },
        502: {
            errorHeading: t('errors.badGatewayHeader'),
            errorMessage: t('errors.badGatewayMsg'),
        },
        503: {
            errorHeading: t('errors.serviceUnavailableHeader'),
            errorMessage: t('errors.serviceUnavailableMsg'),
        },
    };

    if (Object.hasOwn(errorMessageMap, error.statusCode ?? 0)) {
        return errorMessageMap[error.statusCode ?? 0];
    } else if (error.statusCode && error.statusMessage) {
        return defaultMessage;
    }

    return fallbackMessage;
};
