
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "es",
  "fr",
  "it",
  "pl"
]

export const localeLoaders = {
  "de": [{ key: "../i18n/locales/de-DE.json", load: () => import("../i18n/locales/de-DE.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_de_DE_json" */), cache: true }],
  "en": [{ key: "../i18n/locales/en-GB.json", load: () => import("../i18n/locales/en-GB.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_en_GB_json" */), cache: true }],
  "es": [{ key: "../i18n/locales/es-ES.json", load: () => import("../i18n/locales/es-ES.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_es_ES_json" */), cache: true }],
  "fr": [{ key: "../i18n/locales/fr-FR.json", load: () => import("../i18n/locales/fr-FR.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_fr_FR_json" */), cache: true }],
  "it": [{ key: "../i18n/locales/it-IT.json", load: () => import("../i18n/locales/it-IT.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_it_IT_json" */), cache: true }],
  "pl": [{ key: "../i18n/locales/pl-PL.json", load: () => import("../i18n/locales/pl-PL.json" /* webpackChunkName: "locale__buddy_adamhall_sw6_frontends_ahshop_i18n_locales_pl_PL_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/i18n.config.ts?hash=5b32d2e0&config=1" /* webpackChunkName: "i18n_config_5b32d2e0" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de",
      "language": "de-DE",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/de-DE.json"
      ]
    },
    {
      "code": "en",
      "language": "en-GB",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/en-GB.json"
      ]
    },
    {
      "code": "es",
      "language": "es-ES",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/es-ES.json"
      ]
    },
    {
      "code": "fr",
      "language": "fr-FR",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/fr-FR.json"
      ]
    },
    {
      "code": "it",
      "language": "it-IT",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/it-IT.json"
      ]
    },
    {
      "code": "pl",
      "language": "pl-PL",
      "files": [
        "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/pl-PL.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "https://www.adamhall.com",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "language": "de-DE",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/de-DE.json"
      }
    ]
  },
  {
    "code": "en",
    "language": "en-GB",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/en-GB.json"
      }
    ]
  },
  {
    "code": "es",
    "language": "es-ES",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/es-ES.json"
      }
    ]
  },
  {
    "code": "fr",
    "language": "fr-FR",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/fr-FR.json"
      }
    ]
  },
  {
    "code": "it",
    "language": "it-IT",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/it-IT.json"
      }
    ]
  },
  {
    "code": "pl",
    "language": "pl-PL",
    "files": [
      {
        "path": "/buddy/adamhall-sw6/frontends/ahshop/i18n/locales/pl-PL.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
