import revive_payload_client_4sVQNw7RlN from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import plugin_client_3TaWzsV3hk from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt-mollie-payments-components/dist/runtime/plugins/plugin.client.mjs";
import payload_client_yVLowv6hDl from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/buddy/adamhall-sw6/frontends/ahshop/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_BqLkNUef68 from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_Bmd1ANo9CH from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt-cache-keys/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import shopware_plugin_txRT4f0fRY from "/buddy/adamhall-sw6/frontends/ahshop/.nuxt/runtime/shopware.plugin.mjs";
import unocss_MzCDxu9LMj from "/buddy/adamhall-sw6/frontends/ahshop/.nuxt/unocss.mjs";
import ssr_plugin_B4ptqVdIfe from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import algolia_tracking_client_nZwJHDsp3n from "/buddy/adamhall-sw6/frontends/ahshop/plugins/algolia-tracking.client.ts";
import chunk_reload_immediate_client_uW4u1tW6oD from "/buddy/adamhall-sw6/frontends/ahshop/plugins/chunk-reload-immediate.client.ts";
import dompurify_html_VcfsMfUvBB from "/buddy/adamhall-sw6/frontends/ahshop/plugins/dompurify-html.ts";
import lazy_hydration_ISm2rlWKO7 from "/buddy/adamhall-sw6/frontends/ahshop/plugins/lazy-hydration.ts";
import maska_UHaKf2z1iQ from "/buddy/adamhall-sw6/frontends/ahshop/plugins/maska.ts";
import sentry_client_shVUlIjFLk from "/buddy/adamhall-sw6/frontends/ahshop/plugins/sentry.client.ts";
import ssg_detect_3fHkBxLtv0 from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  plugin_client_3TaWzsV3hk,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_BqLkNUef68,
  plugin_Bmd1ANo9CH,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  shopware_plugin_txRT4f0fRY,
  unocss_MzCDxu9LMj,
  ssr_plugin_B4ptqVdIfe,
  algolia_tracking_client_nZwJHDsp3n,
  chunk_reload_immediate_client_uW4u1tW6oD,
  dompurify_html_VcfsMfUvBB,
  lazy_hydration_ISm2rlWKO7,
  maska_UHaKf2z1iQ,
  sentry_client_shVUlIjFLk,
  ssg_detect_3fHkBxLtv0
]