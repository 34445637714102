// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useCart as swUseCart } from '@shopware-pwa/composables-next';
import type { Schemas } from '#shopware';
import type { AhCart, AhCartItem, AhCartItemPayload, AhCartParams } from '~/types/models/cart';
import { Price } from '~/helpers/Price';
import { computed } from 'vue';

export enum ErrorLevel {
    NOTICE = 0,
    WARNING = 10,
    ERROR = 20,
}

/** override sw composable with custom ah properties */
export const useCart = () => {
    const useCartData = swUseCart();
    const { apiClient } = useShopwareContext();

    const _storeCart = useContext<Schemas['Cart']>('swCart');
    const _storeCartErrors = useContext<Schemas['Cart']['errors'] | null>('swCartErrors');

    const route = useRoute();
    const isCartRoute = computed(() => String(route.name)?.startsWith('checkout-cart'));
    const isCheckoutConfirmRoute = computed(() => String(route.name)?.startsWith('checkout___'));

    /** use own route for promotions */
    async function submitPromotionCode(promotionCode: string): Promise<Schemas['Cart']> {
        const res = await apiClient.invoke('addPromotion post /promotion/add', {
            code: promotionCode,
            isConfirm: isCheckoutConfirmRoute.value,
        });

        useCartData.refreshCart(res);
        return res;
    }

    const hasBlockErrors = computed(() => {
        let errors: Schemas['CartError'][] = [];
        const cartErrors = (useCartData.cart.value as AhCart)?.errors;

        if (Array.isArray(cartErrors)) {
            errors = cartErrors;
        } else if (typeof cartErrors === 'object' && cartErrors !== null) {
            errors = Object.values(cartErrors);
        }

        return errors.some((error) => error?.block);
    });

    const cartObjectIds = computed(() =>
        useCartData.cartItems.value
            ?.filter((item) => item.type === 'product')
            ?.map((item) => (item?.payload as AhCartItemPayload)?.productNumber),
    );

    const cartObjectData = computed(() => {
        const localStorageKey = 'algoliaCartData';
        const storedCartData: Array<{ queryID: string; objectID: string }> = JSON.parse(
            localStorage.getItem(localStorageKey) || '[]',
        );

        return useCartData.cartItems.value
            ?.filter((item) => item.type === 'product')
            ?.map((item) => {
                // Find the matching queryID for the current item by objectID
                const matchingEntry = storedCartData.find(
                    // @ts-ignore productNumber doesnt exist on type
                    (cartItem) => cartItem.objectID === item.payload?.productNumber,
                );

                return {
                    price: item.price.totalPrice,
                    quantity: item.quantity,
                    ...((item.payload as AhCartItemPayload)?.price?.discountPrice && {
                        discount: Price.round2Decimals((item.payload as AhCartItemPayload)?.price?.discountPrice ?? 0),
                    }),
                    // Add the queryID if a match is found
                    ...(matchingEntry && { queryID: matchingEntry.queryID }),
                };
            });
    });

    const promotionLineItem = computed(() =>
        (useCartData.cartItems.value as AhCart)?.find((item: AhCartItem) => item.type === 'promotion'),
    );

    const removePromoError = () => {
        let errors: Schemas['CartError'][] = [];
        const cartErrors = (useCartData.cart.value as AhCart)?.errors;

        if (Array.isArray(cartErrors)) {
            errors = cartErrors;
        } else if (typeof cartErrors === 'object' && cartErrors !== null) {
            errors = Object.values(cartErrors);
        }

        const removePromoError = errors.filter((error) => error.key !== 'promotion-not-found');
        if (useCartData.cart.value) {
            useCartData.cart.value.errors = removePromoError;
        }
    };

    const addProduct = async (params: { id: string; quantity: number }) => {
        const response = await apiClient.invoke('addLineItem post /checkout/cart/line-item', {
            items: [
                {
                    id: params.id,
                    referencedId: params.id,
                    quantity: params.quantity,
                    type: 'product',
                },
            ],
            isCart: isCartRoute.value,
        });
        _storeCart.value = response;
        setCartErrors(response);

        return response;
    };

    const changeProductQuantity = async (params: { id: string; quantity: number }, cartParams: AhCartParams = {}) => {
        const response = await apiClient.invoke('updateLineItem patch /checkout/cart/line-item', {
            items: [
                {
                    id: params.id,
                    quantity: +params.quantity,
                },
            ],
            isCart: isCartRoute.value,
            ...cartParams,
        });
        _storeCart.value = response;
        setCartErrors(response);

        return response;
    };

    const setCartErrors = (cart: Schemas['Cart']) => {
        if (Object.keys(cart.errors || {}).length) {
            _storeCartErrors.value = Object.assign(_storeCartErrors.value ? _storeCartErrors.value : {}, cart.errors);
        }
    };

    return {
        ...useCartData,
        isCartRoute,
        removePromoError,
        hasBlockErrors,
        addPromotionCode: submitPromotionCode,
        addProduct,
        changeProductQuantity,
        cartObjectIds,
        cartObjectData,
        promotionLineItem,
    };
};
