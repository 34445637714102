export class ObjectHelper {
    static merge<T extends { [key in keyof T]: unknown }>(target: T, ...sources: T[]): T {
        if (!sources.length) return target;
        const source = sources.shift();

        if (source === undefined) {
            return target;
        }

        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    this.merge(target[key], source[key]);
                } else if (this.isArray(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: [] });
                    // @ts-ignore
                    Object.assign(target, { [key]: [...target[key], ...source[key]] });
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return this.merge(target, ...sources);
    }

    static isObject<T>(item: T): boolean {
        return item && typeof item === 'object' && !Array.isArray(item);
    }

    static isArray<T>(item: T): boolean {
        return item && typeof item === 'object' && Array.isArray(item);
    }
}
