export default defineAppConfig({
    shopTitle: 'Adam Hall Shop',
    countryLocales: {
        es: [
            'ES',
            'MX',
            'AR',
            'CO',
            'PE',
            'VE',
            'CL',
            'GT',
            'EC',
            'BO',
            'CU',
            'DO',
            'HN',
            'PY',
            'SV',
            'NI',
            'CR',
            'PA',
            'UY',
            'GQ',
        ],
        fr: [
            'BE',
            'BJ',
            'BF',
            'BI',
            'CM',
            'CA',
            'CF',
            'TD',
            'KM',
            'CD',
            'CG',
            'DJ',
            'GQ',
            'FR',
            'GA',
            'GN',
            'HT',
            'CI',
            'LU',
            'MG',
            'MC',
            'NE',
            'RW',
            'SN',
            'SC',
            'CH',
            'VU',
        ],
        de: ['DE', 'AT', 'CH', 'BE', 'LU', 'LI'],
        it: ['IT', 'CH', 'SM', 'VA'],
        pl: ['PL'],
    },
    downloads: {
        maxFiles: 12, // maximum files to show, when no filter is selected
        minFilesToFilter: 6, // minimum files to show the filter
        maxFilters: 99, // maximum filters (without 'all')
        categoryFilters: [
            { id: 'manual', icon: 'SvgoUserDesk' },
            { id: 'data_sheet_specification', icon: 'SvgoDataSheet' },
            { id: 'software', icon: 'SvgoDesktopArrowDown' },
            { id: 'presets', icon: 'SvgoSquareSlides' },
            { id: 'cad_drawing', icon: 'SvgoFileCad' },
            { id: 'images', icon: 'SvgoImages' },
            { id: 'catalog_publication', icon: 'SvgoBookCover' },
            { id: 'service_regulation_documents', icon: 'SvgoMemoCircleInfo' },
            { id: 'technical_service_documents', icon: 'SvgoFileVector' },
            { id: 'certificates_declarations', icon: 'SvgoFileCertificate' },
        ],
    },
    algolia: {
        sorting: [
            'relevance',
            'product_number_asc',
            'product_number_desc',
            'resolved_date_desc',
            'uvp_price_asc',
            'uvp_price_desc',
        ],
        restrictedSorting: ['uvp_price_asc', 'uvp_price_desc'],
        facetsCacheKey: 'facets',
        facetsCacheTTL: 14400, // = 4h
    },
    sentry: {
        // errors that should not be sent to sentry
        ignoreErrors: [
            'ENOENT',
            'Cannot find static asset',
            'Failed to fetch dynamically imported module',
            'error loading dynamically imported module',
            'ChunkLoadError',
            'Loading chunk',
            '_nuxt/builds/latest.json',
            '<no response> Failed to fetch',
            '[Unknown error] API did not return errors',
        ],
    },
});
