// redirect /search?sSearch= to /search?query=
export default defineNuxtRouteMiddleware((to) => {
    const sSearch = to.query.sSearch;

    if (sSearch && to.path.endsWith('/search')) {
        const query = { ...to.query, query: sSearch };
        // @ts-ignore
        delete query.sSearch;

        return navigateTo(
            { path: to.path, query },
            { replace: true, redirectCode: import.meta.server ? 301 : undefined },
        );
    }
});
