import routerOptions0 from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/buddy/adamhall-sw6/frontends/ahshop/app/router.options.ts";
const configRouterOptions = {
  linkExactActiveClass: "text-brand-primary",
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}