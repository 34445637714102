import TitilliumWeb400 from '~/assets/fonts/titillium-web-v15-latin-regular.woff2';
import TitilliumWeb700 from '~/assets/fonts/titillium-web-v15-latin-700.woff2';

export const useAppHead = () => {
    const { t } = useI18n();
    const config = useRuntimeConfig();
    const { shopTitle } = useAppConfig();

    if (import.meta.server) {
        useHead({
            link: [
                {
                    rel: 'preload',
                    href: TitilliumWeb400,
                    crossorigin: '',
                    as: 'font',
                    type: 'font/woff2',
                },
                {
                    rel: 'preload',
                    href: TitilliumWeb700,
                    crossorigin: '',
                    as: 'font',
                    type: 'font/woff2',
                },
            ],
            meta: [
                {
                    name: 'application-name',
                    content: shopTitle,
                },
                {
                    name: 'apple-mobile-web-app-title',
                    content: shopTitle,
                },
            ],
        });
    }

    if (import.meta.client) {
        // Prevent ConsentManager from blocking specific URLs
        const whitelist = String(config.public.cmDomainWhitelist);
        const domainList = whitelist?.length ? whitelist.split(',') : [];

        if (config.public.scaleCommerceCdnUrl) {
            domainList.push(UrlHelper.getDomainFromString(config.public.scaleCommerceCdnUrl as string));
        }

        window.cmp_block_ignoredomains = domainList;
    }

    useHead({
        title: t('meta.title'),
        htmlAttrs: {
            lang: t('meta.htmlAttrsLang'),
        },
    });
};
