import {
    BrowserClient,
    makeFetchTransport,
    defaultStackParser,
    getCurrentScope,
    getDefaultIntegrations,
    vueIntegration,
} from '@sentry/vue';
import type { SentryConfig } from '~/types/config/sentry';
import type { SentryAppConfig } from '~/types/app';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const sentryConfig = config.public.sentry as SentryConfig;
    const sentryAppConfig = useAppConfig().sentry as SentryAppConfig;
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (sentryConfig.dsn) {
        const client = new BrowserClient({
            // @ts-ignore
            app: nuxtApp.vueApp,
            dsn: sentryConfig.dsn,
            environment: sentryConfig.environment,
            sampleRate: parseFloat(sentryConfig.sampleRate.toString()),
            initialScope: {
                tags: { renderer: 'client' },
            },
            ignoreErrors: sentryAppConfig.ignoreErrors,
            transport: makeFetchTransport,
            stackParser: defaultStackParser,
            integrations: [...getDefaultIntegrations({}), vueIntegration()],
        });

        getCurrentScope().setClient(client);
        client.init();
    }

    const originalErrorHandler = nuxtApp.vueApp.config.errorHandler;
    nuxtApp.vueApp.config.errorHandler = (msg, vm, info) => {
        if (isDevelopment && originalErrorHandler) {
            originalErrorHandler(msg, vm, info);
        } else {
            Logger.captureException(msg);
        }
    };
});
