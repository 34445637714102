import type { AlgoliaDefaultFilterOptions } from '~/types/models/algoliaFilter';
import { ObjectHelper } from '~/helpers/ObjectHelper';
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';
import { Sorter } from '~/helpers/Sorter';

export const useAlgolia = () => {
    const { locale } = useI18n();
    const { isLoggedIn } = useUser();
    const { algoliaIndexPrefix, algoliaIndexPostfix } = useAhConfig();
    const productIndex = computed(
        () => `${algoliaIndexPrefix.value}_product_${locale.value}_${algoliaIndexPostfix.value}`,
    );
    const categoryIndex = computed(
        () => `${algoliaIndexPrefix.value}_category_${locale.value}_${algoliaIndexPostfix.value}`,
    );
    const { search } = useAlgoliaSearch(productIndex.value);
    const _facetFilters = useState<string[]>(`facet-filters-${locale.value}`, () => []);

    const getProducts = async (
        ids: string[],
        customOptions: Partial<AlgoliaDefaultFilterOptions> = {},
        hitsPerPage: undefined | number = undefined,
    ): Promise<AlgoliaProduct[]> => {
        try {
            if (ids.length === 0) {
                return [];
            }

            const facets = ids.map((id) => `productId:${id}`);

            const defaultFacetFilters = getDefaultFacetFilters(customOptions);
            const response = await search({
                query: '',
                requestOptions: {
                    // @ts-ignore
                    facetFilters: [...defaultFacetFilters, facets],
                    hitsPerPage,
                    clickAnalytics: true,
                },
            });

            const hits = (response?.hits ?? []) as AlgoliaProduct[];
            if (hits.length === 0) return [];

            // order hits by the configured order
            return Sorter.sortByDefinedOrder(hits, ids, 'productId');
        } catch (error) {
            Logger.captureException(error);
        }

        return [];
    };

    const getProduct = async (id: string): Promise<AlgoliaProduct | null> => {
        try {
            const facets = [`productId:${id}`];

            const defaultFacetFilters = getDefaultFacetFilters({ archive: true, sparePart: true, bWare: true });
            const response = await search({
                query: '',
                requestOptions: {
                    // @ts-ignore
                    facetFilters: [...defaultFacetFilters, facets],
                    clickAnalytics: true,
                },
            });

            const hits = (response?.hits ?? []) as AlgoliaProduct[];

            return hits.length ? hits[0] : null;
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const getDefaultFacetFilters = (customOptions: Partial<AlgoliaDefaultFilterOptions> = {}) => {
        const defaultOptions: AlgoliaDefaultFilterOptions = {
            archive: false,
            sparePart: false,
            bWare: false,
        };
        const options: AlgoliaDefaultFilterOptions = ObjectHelper.merge(
            defaultOptions,
            customOptions as AlgoliaDefaultFilterOptions,
        );

        return [
            !options.sparePart && 'isSparePart:false',
            !options.archive && 'isArchive:false',
            (!isLoggedIn.value || !options.bWare) && 'isBware:false',
        ].filter((filter) => filter) as string[] | string[][];
    };

    const getFacetFilters = async (): Promise<string[]> => {
        try {
            if (_facetFilters.value?.length) {
                return _facetFilters.value;
            }

            const cachedFacets = await $fetch(`/api/${locale.value}/get-listing-filters`);
            if (cachedFacets) {
                _facetFilters.value = cachedFacets;
                return _facetFilters.value;
            }

            const searchResponse = await search({
                query: '',
                requestOptions: {
                    facets: ['*'],
                    hitsPerPage: 1,
                },
            });

            if (searchResponse.facets) {
                const facets = Object.keys(searchResponse.facets);
                _facetFilters.value = facets;

                await $fetch(`/api/${locale.value}/set-listing-filters`, {
                    method: 'POST',
                    body: {
                        facets,
                    },
                });
            }
        } catch (err) {
            Logger.captureException(err);
        }

        return _facetFilters.value;
    };

    return {
        productIndex,
        categoryIndex,
        getDefaultFacetFilters,
        getFacetFilters,
        getProducts,
        getProduct,
    };
};
