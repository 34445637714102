// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useSessionContext as swUseSessionContext } from '@shopware-pwa/composables-next';
import type { Schemas } from '#shopware';

export type ahExtendedSessionContext = Partial<Schemas['SalesChannelContext']> & {
    extensions: {
        selectedShippingMethodOptionNumber: {
            selectedShippingMethodOptionNumber: string;
        };
        selectedAhShippingAddressId: {
            ahShippingAddressId: string;
        };
        globalBankAccountId: {
            globalBankAccountId: string;
        };
        paymentMethodId: {
            selectedPaymentMethodId: string;
        };
        defaultAhPaymentmeanId: {
            defaultAhPaymentmeanId: string;
        };
    };
    displayCurrency: Schemas['Currency'];
    currencies: Schemas['Currency'][];
    language: Schemas['Language'] | null;
    domain: Schemas['SalesChannelDomain'] | null;
    customerAttributes: Schemas['AhCustomerAttributes'] | null;
    paymentMeansId: string;
};

/** override sw composable with custom ah properties */
export const useSessionContext = () => {
    const useSwSessionContext = swUseSessionContext();

    // unset properties -> shipping method is handled in useShippingMethod
    // keep same interface to prevent type errors from shopware-pwa+composables-nex
    const selectedShippingMethod = computed(() => null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const setShippingMethod = (shippingMethod: { id: string }) => Promise.resolve();

    const sessionContext = computed(() => useSwSessionContext.sessionContext.value as ahExtendedSessionContext);
    const selectedAhShippingAddressId = computed(
        () =>
            (useSwSessionContext.sessionContext.value as ahExtendedSessionContext)?.extensions
                ?.selectedAhShippingAddressId?.ahShippingAddressId || null,
    );

    const defaultPaymentMethodId = computed(
        () => useSwSessionContext.sessionContext.value?.customer?.defaultPaymentMethodId ?? null,
    );

    const displayCurrency = computed(
        () => (useSwSessionContext.sessionContext.value as ahExtendedSessionContext)?.displayCurrency?.isoCode,
    );
    const currencyCode = computed(() => useSwSessionContext.sessionContext.value?.currency?.isoCode);
    const isDisplayCurrencySame = computed(() => currencyCode.value === displayCurrency.value);

    return {
        ...useSwSessionContext,
        selectedShippingMethod,
        setShippingMethod,
        sessionContext,
        selectedAhShippingAddressId,
        defaultPaymentMethodId,
        displayCurrency,
        isDisplayCurrencySame,
    };
};
