import { provide, ref } from 'vue';

/*
 * Composable useOffcanvas() is used once as global app call to provide the basic offcanvas structure
 *
 * Example usage in template:
 * - button with offcanvas.open function
 * - SharedOffcanvasContent which uniquely belongs to button (id param in open function and id property need to be the same)
 <template>
    <button
        @click="offcanvas.open('CustomComponent', 'offcanvas-open-button-id', {})"
    >
        Open offcanvas
    </button>
    <SharedOffcanvasContent
        id="offcanvas-open-button-id"
        :offcanvas="offcanvas"
    />
 </template>
 */
export function useOffcanvas(): void {
    const offcanvasId = ref<string>('');
    const offcanvasContent = ref<string>('');
    const offcanvasProps = ref<object | null | undefined>({});
    const visible = ref<boolean>(false);

    const offcanvasHandler = {
        open: (component: string, id: string, props?: object | null) => {
            offcanvasId.value = id;
            offcanvasContent.value = component;
            offcanvasProps.value = props;
            visible.value = true;
        },
        close: () => {
            visible.value = false;
        },
        transitionEnd: () => {
            offcanvasContent.value = '';
            offcanvasProps.value = {};
        },
    };

    provide('offcanvas', { offcanvasContent, offcanvasProps, offcanvasId, visible, ...offcanvasHandler });
}
