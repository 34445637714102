export class Sorter {
    static parseFileSize(data: string | null) {
        if (data === null || data === '') {
            return 0;
        }

        const matches = data.match(/^(\d+(?:\.\d+)?)\s*([a-z]+)/i);

        if (!matches) return -1;

        const multipliers = {
            b: 1,
            kb: 1000,
            mb: 1000000,
            gb: 1000000000,
        };

        const index = matches[2].toLowerCase();

        if (index === 'b' || index === 'kb' || index === 'mb' || index === 'gb') {
            const multiplier = multipliers[index];
            return parseFloat(matches[1]) * multiplier;
        } else {
            return -1;
        }
    }

    static sortAlphabetical(property: string, sortOrder: 1 | -1) {
        return function (a: any, b: any) {
            const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    static sortStateAlphabetical(property: string, sortOrder: 1 | -1) {
        const nestedProps = property.split('.');
        const nested1 = nestedProps[0];
        const nested2 = nestedProps[1];

        return function (a: any, b: any) {
            const result =
                a[nested1][nested2] < b[nested1][nested2] ? -1 : a[nested1][nested2] > b[nested1][nested2] ? 1 : 0;
            return result * sortOrder;
        };
    }

    static sortByFileSize(property: string, sortOrder: 1 | -1) {
        return function (a: any, b: any) {
            const result =
                Sorter.parseFileSize(a[property]) < Sorter.parseFileSize(b[property])
                    ? -1
                    : Sorter.parseFileSize(a[property]) > Sorter.parseFileSize(b[property])
                      ? 1
                      : 0;
            return result * sortOrder;
        };
    }

    static sortByDate(property: string, sortOrder: 1 | -1) {
        return function (a: any, b: any) {
            const result =
                new Date(a[property]).getTime() < new Date(b[property]).getTime()
                    ? -1
                    : new Date(a[property]).getTime() > new Date(b[property]).getTime()
                      ? 1
                      : 0;
            return result * sortOrder;
        };
    }

    static sortByPrice(property: string, sortOrder: 1 | -1) {
        return function (a: any, b: any) {
            const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    }

    static sortByDefinedOrder<T>(array: T[], order: any[], key: keyof T): T[] {
        return array.sort((a, b) => {
            let weightA = 0;
            let weightB = 0;

            if (!order.includes(a[key])) {
                weightA += 100;
            }

            if (!order.includes(b[key])) {
                weightB += 100;
            }

            return order.indexOf(a[key]) + weightA - (order.indexOf(b[key]) + weightB);
        });
    }
}
