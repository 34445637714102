import type { ApiClient } from '#shopware';
import type { ShopwareContext } from '@shopware-pwa/composables-next';

// add the sw-context-token to the apiClient headers on server side
export function useShopwareContext() {
    const shopwareContext = inject<ShopwareContext | null>('shopware', null);
    const apiClient = inject<ApiClient>('apiClient');

    const setContextTokenHeader = () => {
        const cookie = useCookie('sw-context-token');
        if (apiClient && cookie.value) apiClient.defaultHeaders['sw-context-token'] = cookie.value;
    };
    setContextTokenHeader();

    if (!shopwareContext || !apiClient) throw new Error('Critical error. Shopware context is not provided.');

    return {
        /**
         * @deprecated use `apiClient` instead
         */
        apiInstance: shopwareContext.apiInstance,
        apiClient,
        devStorefrontUrl: shopwareContext.devStorefrontUrl,
        setContextTokenHeader,
    };
}
