<script lang="ts">
export default {
    props: {
        fontControlled: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: Object,
            required: true,
        },
    },
};
</script>

<template>
    <component
        :is="icon"
        :class="{
            'nuxt-icon': fontControlled,
        }"
    />
</template>

<style>
.nuxt-icon {
    width: 1em;
    height: 1em;
}
</style>
