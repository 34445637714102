<script setup lang="ts">
import type { Ref } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

export type SharedModal = {
    modalId: Ref<string>;
    modalContent: Ref<string>;
    modalProps: Ref<object | null>;
    visible: Ref<boolean>;
    closeOnClickOutside: Ref<boolean>;
    closeOnEscape: Ref<boolean>;
    open: (component: string, id: string, props?: object | null) => void;
    close: () => void;
    size: 'fullscreen' | 'fullscreen-boxed' | 'large' | null;
    transitionEnd: () => void;
};

const modal = ref(null);

const { activate, deactivate } = useFocusTrap(modal);

const { visible, close, transitionEnd, size, closeOnClickOutside, closeOnEscape } = inject<SharedModal>(
    'modal',
) as SharedModal;
if (import.meta.client) {
    // wait for nextTick to activate focusTrap
    watch(visible, async (value) => {
        if (value) {
            await nextTick();
            activate();
        } else {
            await nextTick();
            deactivate();
        }
    });
}
// scroll lock if modal is open
useHead({
    bodyAttrs: {
        class: computed(() => (visible.value ? 'overflow-hidden hide-cookie-banner' : '')),
    },
});

const handleOutsideClick = () => {
    if (closeOnClickOutside.value) {
        close();
    }
};

if (closeOnEscape.value) {
    onMounted(() => {
        useEventListener(window, 'keydown', (e) => {
            if (e.key === 'Escape') {
                close();
            }
        });
    });
}
</script>

<template>
    <Transition
        enterActiveClass="transition ease-out duration-300 transform"
        enterFromClass="opacity-0"
        enterToClass="opacity-100"
        leaveActiveClass="transition ease-in duration-300 transform"
        leaveFromClass="opacity-100"
        leaveToClass="opacity-0"
    >
        <div
            v-show="visible"
            ref="modal"
            class="fixed inset-0 overflow-y-auto z-11 bg-gray-500 bg-opacity-80"
            tabindex="0"
            @click="handleOutsideClick()"
        >
            <div
                class="w-full h-full rounded"
                :class="{
                    grid: size !== 'fullscreen',
                    'lg:p-4 md:px-20 md:py-6': size === 'fullscreen-boxed',
                    'md:place-content-center': size !== 'fullscreen-boxed',
                }"
            >
                <Transition
                    enterActiveClass="transition ease-out duration-300 transform "
                    enterFromClass="opacity-0 translate-y-10 scale-95"
                    enterToClass="opacity-100 translate-y-0 scale-100"
                    leaveActiveClass="ease-in duration-200"
                    leaveFromClass="opacity-100 translate-y-0 scale-100"
                    leaveToClass="opacity-0 translate-y-10 translate-y-0 scale-95"
                    @after-leave="transitionEnd"
                >
                    <div
                        v-show="visible"
                        class="bg-white relative flex flex-col overflow-hidden md:rounded-md md:shadow"
                        :class="{
                            'overflow-hidden': size === 'fullscreen-boxed',
                            'h-full w-full': size === 'fullscreen',
                            'md:w-[740px] xl:w-[1000px] max-h-dvh md:max-h-[90dvh]': size === 'large',
                            'md:w-[740px] max-h-dvh md:max-h-[90dvh]': size === null,
                        }"
                        role="dialog"
                        aria-modal="true"
                    >
                        <div
                            class="absolute top-2 right-2 flex justify-end"
                            :class="{ 'absolute right-0 z-30': size === 'fullscreen' || size === 'fullscreen-boxed' }"
                            @click.stop
                        >
                            <button
                                class="p-4 bg-transparent focus-visible:outline-none"
                                @click="close"
                            >
                                <SvgoXMarkCircle class="text-gray-500 h-7 w-auto" />
                            </button>
                        </div>

                        <div
                            id="modal-content"
                            class="grow"
                            :class="[
                                size === 'fullscreen-boxed' ? ['py-3'] : ['py-5'],
                                size === 'fullscreen' ? ['h-full'] : ['px-5'],
                                { 'overflow-y-auto': size !== 'fullscreen-boxed' },
                            ]"
                            @click.stop
                        />
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<style lang="scss">
.hide-cookie-banner {
    #cmpbox {
        display: none !important;
    }
}
</style>
