import type { DirectiveBinding } from 'vue';
import DOMPurify from 'dompurify';

export default defineNuxtPlugin(async ({ vueApp }) => {
    let purify: typeof DOMPurify;

    if (import.meta.server) {
        const { JSDOM } = await import('jsdom');
        purify = DOMPurify(new JSDOM('').window);
    } else {
        purify = DOMPurify(window);
    }

    function sanitizeHtml(binding: DirectiveBinding) {
        return purify.sanitize(binding.value, {
            FORCE_BODY: true,
            ADD_TAGS: ['script', 'iframe'],
            ADD_ATTR: [
                'builder-id',
                'builder-model',
                'builder-content-id',
                'builder-path',
                'builder-parent-id',
                'fetchpriority',
                'target',
                'scriptsclientonly',
                'url',
                'allow',
                'scrolling',
                'allowfullscreen',
                'lazyload',
            ],
        });
    }

    vueApp.directive('dompurify-html', {
        created(el, binding) {
            el.innerHTML = sanitizeHtml(binding);
        },
        updated(el, binding) {
            el.innerHTML = sanitizeHtml(binding);
        },
        getSSRProps(binding) {
            return {
                innerHTML: sanitizeHtml(binding),
            };
        },
    });
});
