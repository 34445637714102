import type { CmsSection } from '@shopware-pwa/types';

export const stickyHeaderHeight = 145;
export const stickyNavHeight = 60;
export const pdpHeaderHeight = 75;
export const scrollOffset = stickyNavHeight + pdpHeaderHeight;

export type NavTarget = {
    id: string;
    element: HTMLElement;
    bounding: DOMRect;
};

export type NavItem = {
    id: string;
    name: string;
};

const _navElementRefs = ref<NavTarget[]>([]);
const _productNavigationElement = ref<HTMLElement | null>(null);
const _productNavigationElementRect = ref<DOMRect | null>(null);

// Composable for sticky navigation on product detail page
export function useDetailPageNavigation() {
    const router = useRouter();
    const { headerVisible } = useHeaderState();
    const { nothingFound } = usePrice2Spy();

    const navElementRect = computed(() => _productNavigationElementRect.value);
    const navTargets = computed<NavTarget[]>(() =>
        _navElementRefs.value.slice().sort((a, b) => parseInt(a.id) - parseInt(b.id)),
    );
    const sections = inject('cmsSections', ref(null));

    // Add nav target with id, element and visible status to the list of nav targets
    const addNavTarget = (navElement: Ref<HTMLElement | undefined>, blockPosition: number, sectionPosition: number) => {
        if (!navElement.value || !navElement.value?.hasAttribute('id')) return;

        const newTarget = {
            id: getBlockId(blockPosition, sectionPosition),
            element: navElement,
            bounding: useElementBounding(navElement),
        } as unknown as NavTarget;

        _navElementRefs.value.push(newTarget);
    };

    // create block id from section id and block position
    const getBlockId = (blockPosition: number, sectionPosition: number): string => {
        return `${sectionPosition}${blockPosition}`;
    };

    // whitelist of nav items (not all blocks should be shown in nav)
    const navItemsWhitelist = [
        'product-features',
        'product-specifications',
        'product-description',
        'awards',
        'video-slider',
        'accessories-slider',
        'product-spare-parts',
        'product-download',
        'stories-slider',
        'product-apps',
        'price2spy',
    ];

    // create nav items from sections and blocks
    const navItems = computed(() => {
        let blocks: NavItem[] | [] = [];

        if (sections.value === null) return blocks;
        (sections.value as CmsSection[]).forEach((section: CmsSection) => {
            const blockNavItems = section.blocks.map((block) => {
                return {
                    id: getBlockId(block.position, section.position),
                    name: block.type,
                };
            });

            if (blockNavItems.length > 0) blocks = [...blocks, ...blockNavItems];
        });

        // check if block is in whitelist
        blocks = blocks.filter((block: NavItem) => navItemsWhitelist.includes(block.name));
        // check if block is in nav targets
        blocks = blocks.filter((block: NavItem) => navTargets.value.find((navTarget) => navTarget.id === block.id));

        if (nothingFound.value) {
            blocks = blocks.filter((block: NavItem) => block.name !== 'price2spy');
        }

        return blocks || [];
    });

    const getActiveNavItem = (): NavTarget | undefined => {
        if (!navElementRect.value) return;

        const overlapSpacing = 10;
        const targetsCount = navTargets.value.length;
        const maxIndex = targetsCount - 1;

        return navTargets.value.find((target, index) => {
            const top = target.bounding.top;
            const navBottom = navElementRect.value!.bottom;
            const topWithoutSticky = top - navBottom;

            if (index === 0 && topWithoutSticky >= 0) {
                return true;
            }

            const bottom = target.bounding.bottom;

            const elementInView = bottom - navBottom >= overlapSpacing && top - navBottom <= overlapSpacing;
            if (elementInView) {
                return true;
            }

            if (index < maxIndex) {
                const nextTarget = navTargets.value[index + 1];
                const nextTargetTop = nextTarget.bounding.top;

                const hasElementsBetweenNext = bottom - nextTargetTop < 0;
                if (hasElementsBetweenNext && nextTargetTop - navBottom >= overlapSpacing) {
                    return true;
                }
            }

            return index === maxIndex;
        });
    };

    // update hash in url when nav item is clicked
    const _updateHash = (navItemId: string) => {
        const targetElement = navTargets.value.find((navTarget) => navTarget.id === navItemId)?.element;
        if (!targetElement) return;

        const id = targetElement.id ?? undefined;

        if (typeof id === 'string') {
            router.push({ hash: `#${id}` });
        }
    };

    const onNavItemClick = (id: string) => {
        // if normal header is visible, we need to scroll to the product navigation element first
        if (headerVisible.value && _productNavigationElement.value) {
            _productNavigationElement.value.scrollIntoView({ behavior: 'smooth' });

            setTimeout(() => {
                _updateHash(id);
            }, 400);
        } else {
            _updateHash(id);
        }
    };

    const updateNavElementRect = () => {
        if (!_productNavigationElement.value) {
            _productNavigationElementRect.value = null;
            return;
        }

        _productNavigationElementRect.value = _productNavigationElement.value.getBoundingClientRect();
    };

    const cleanUp = () => {
        _navElementRefs.value = [];
    };

    return {
        _productNavigationElement,
        _navElementRefs,
        navElementRect,
        navTargets,
        addNavTarget,
        getBlockId,
        navItems,
        onNavItemClick,
        updateNavElementRect,
        getActiveNavItem,
        cleanUp,
    };
}
