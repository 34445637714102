type ModalOpenProps = Record<string, any> & {
    size?: string;
    closeOnClickOutside?: boolean;
    closeOnEscape?: boolean;
    beforeCloseCheck?: () => boolean;
};
/**
 * Composable useModal() is used once as global app call to provide the basic modal structure
 *
 * Example usage in template:
 * - button with modal.open() function
 * - SharedModalContent which uniquely belongs to button (id param in open function and id property need to be the same)
 <template>
    <button
        @click="modal.open('CustomComponent', 'modal-open-button-id', {})"
    >
        Open modal
    </button>
    <SharedModalContent
        id="modal-open-button-id"
        :modal="modal"
    />
 </template>
 */
export function useModal(): void {
    const modalId = ref<string>('');
    const modalContent = ref<string>('');
    const modalProps = ref<ModalOpenProps | undefined>(undefined);
    const visible = ref<boolean>(false);
    const size = ref<string | null>(null);
    const closeOnClickOutside = ref<boolean>(true);
    const closeOnEscape = ref<boolean>(true);
    let beforeCloseCheck: ModalOpenProps['beforeCloseCheck'] = undefined;

    const modalHandler = {
        open: (component: string, id: string, props?: ModalOpenProps) => {
            if (props?.size) {
                size.value = props.size;
                delete props.size;
            }
            if (typeof props?.closeOnClickOutside === 'boolean') {
                closeOnClickOutside.value = props.closeOnClickOutside;
                delete props.closeOnClickOutside;
            }
            if (typeof props?.closeOnEscape === 'boolean') {
                closeOnEscape.value = props.closeOnEscape;
                delete props.closeOnEscape;
            }
            if (typeof props?.beforeCloseCheck === 'function') {
                beforeCloseCheck = props.beforeCloseCheck;
                delete props.beforeCloseCheck;
            }

            modalId.value = id;
            modalContent.value = component;
            modalProps.value = props;

            visible.value = true;
        },
        close: () => {
            if (typeof beforeCloseCheck === 'function' && !beforeCloseCheck()) return;
            visible.value = false;
        },
        transitionEnd: () => {
            modalContent.value = '';
            modalProps.value = {};
        },
    };

    provide('modal', {
        modalContent,
        modalProps,
        modalId,
        visible,
        size,
        closeOnClickOutside,
        closeOnEscape,
        ...modalHandler,
    });
}
