import type { RequestReturnType } from '#shopware';

/**
 * Composable to get ah-config from the admin
 */
export function useAhConfig() {
    const { apiClient } = useShopwareContext();
    const { data: config } = useNuxtData<RequestReturnType<'getConfig'> | null>('ah-config');

    const getAhConfig = async (): Promise<RequestReturnType<'getConfig'> | null> => {
        try {
            const response = await apiClient.invoke('getConfig get /ah-config');
            if (response && typeof response === 'object') {
                return response;
            }
        } catch (error) {
            Logger.captureException(error);
        }

        return null;
    };

    const algoliaIndexPrefix = computed<string>(() => {
        return config.value?.['core.config.algoliaIndexPrefix'] ?? '';
    });

    const algoliaIndexPostfix = computed<string>(() => {
        return config.value?.['core.config.algoliaIndexPostfix'] ?? '';
    });

    const imageCacheBreaker: ComputedRef<string> = computed(() => {
        return config.value?.['core.config.ahCacheBreaker'] || '';
    });

    return {
        getAhConfig,
        algoliaIndexPrefix,
        algoliaIndexPostfix,
        imageCacheBreaker,
    };
}
