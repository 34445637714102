import validate from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45guard_45global from "/buddy/adamhall-sw6/frontends/ahshop/middleware/auth-guard.global.ts";
import redirect_45global from "/buddy/adamhall-sw6/frontends/ahshop/middleware/redirect.global.ts";
import search_45param_45global from "/buddy/adamhall-sw6/frontends/ahshop/middleware/search-param.global.ts";
import trailing_45slash_45global from "/buddy/adamhall-sw6/frontends/ahshop/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/buddy/adamhall-sw6/frontends/ahshop/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45guard_45global,
  redirect_45global,
  search_45param_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  mycustomer: () => import("/buddy/adamhall-sw6/frontends/ahshop/middleware/mycustomer.ts")
}